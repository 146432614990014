import RightPrivateChatListStyles from '../../css/chat-css/RightPrivateChatList.module.css';


function RightPrivateChatList({chatList}){
    return (
        <div className= {RightPrivateChatListStyles.right_private_chatList}>
            <div className={RightPrivateChatListStyles.right_private_search_box}>
                <input type="text" className={RightPrivateChatListStyles.right_private_search_input}/>
                <button className={RightPrivateChatListStyles.right_private_search_btn}>🔍</button>
            </div>
            <div className={RightPrivateChatListStyles.right_private_chat_box_container}>
                {
                chatList.map((chatRooom)=>{
                    return <RightPrivateChat chatRoom = {chatRooom}/>
                })}
            </div>
        </div>
    );
}

function RightPrivateChat({chatRoom}){
    return (
        <div className={RightPrivateChatListStyles.right_private_chat_box}>
            <div className={RightPrivateChatListStyles.right_private_chat_box_left_box}>
                <img src="" alt="" className={RightPrivateChatListStyles.right_private_chat_box_left_box_img} />
            </div>
            <div className={RightPrivateChatListStyles.right_private_chat_box_right_box}>
                <div className={RightPrivateChatListStyles.right_private_chat_box_right_box_title}>Friend Name</div>
                <div className={RightPrivateChatListStyles.right_private_chat_box_right_box_last_chat}>{chatRoom.content}</div>
                <div className={RightPrivateChatListStyles.right_private_chat_box_alram_icon}>🔔</div>
                <div className={RightPrivateChatListStyles.right_private_chat_box_reading_num}>{chatRoom.numberOfUnreadMessage}</div>
            </div>
        </div>
    );
}

export default RightPrivateChatList;