import React from "react";
import TeamPage from "./TeamPage";
import items from './TeamBox.json';

function Team(){
    return(
        <>
            <TeamPage items={items}/>
        </>
    );
}

export default Team;