import '../../css/signup-css/SignupComplete.css';
import { useNavigate } from 'react-router-dom';

function SignupComplete() {
  const navigate = useNavigate();

  const goToTeam =  () => {
    navigate("/team");
  }

  return (
    <div className="App">
      <div id="signup-finalBox">
        <h2>SNACKS</h2>
        <div id="completeBox">
          <div id='up-box'>
            <p id='sub-title'>가입이 완료 되었습니다!</p>
          </div>
          <a href=''>
            <div id="down-box">
              <button onClick={goToTeam} id="btn">확인</button>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default SignupComplete;
