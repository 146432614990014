import React from "react";
import styles from "../../css/left_side.module.css"
import ChatPage from "./ChatPage";
import chatList from './chatList.json';

function Chat(){
    return(
        <>
            <div className={styles.chat}><ChatPage chatList={chatList} /></div>
        </>
    );
}

export default Chat;