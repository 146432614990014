import React from "react";
import styles from "../../css/left_side.module.css"
import { useState } from "react";

import Make_Team from './make_team';
import Make_Post from "./make_post";


function Create(){
    const [maketeam, setMaketeam] = useState(false);
    const [post, setPost] = useState(false);
    return(
        <>
        
        <div className={styles.make_box}>
           
            <button className={styles.make_button}
            onClick={ () => {
                setMaketeam(true);}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="40" viewBox="0 0 35 22">
                <path className={styles.img} id = "img" fill="black" d="M23.8636 9.42857C26.5045 9.42857 28.6205 7.32286 28.6205 4.71429C28.6205 2.10571 26.5045 0 23.8636 0C21.2227 0 19.0909 2.10571 19.0909 4.71429C19.0909 7.32286 21.2227 9.42857 23.8636 9.42857ZM11.1364 9.42857C13.7773 9.42857 15.8932 7.32286 15.8932 4.71429C15.8932 2.10571 13.7773 0 11.1364 0C8.49545 0 6.36364 2.10571 6.36364 4.71429C6.36364 7.32286 8.49545 9.42857 11.1364 9.42857ZM11.1364 12.5714C7.42955 12.5714 0 14.41 0 18.0714V22H22.2727V18.0714C22.2727 14.41 14.8432 12.5714 11.1364 12.5714ZM23.8636 12.5714C23.4023 12.5714 22.8773 12.6029 22.3205 12.65C24.1659 13.97 25.4545 15.7457 25.4545 18.0714V22H35V18.0714C35 14.41 27.5705 12.5714 23.8636 12.5714Z"/>
                </svg>
                <br/>
                팀 생성
                </button>
                
                <button className={styles.make_button}
                onClick={() => setPost(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="40" viewBox=" 0 3 38 25" fill="none">
                    <path className={styles.img} fill-rule="evenodd" clip-rule="evenodd" d="M22.3327 2.5C22.3327 1.98223 21.885 1.5625 21.3327 1.5625C20.7804 1.5625 20.3327 1.98223 20.3327 2.5V4.375H11.666V2.5C11.666 1.98223 11.2183 1.5625 10.666 1.5625C10.1137 1.5625 9.66602 1.98223 9.66602 2.5V4.375H9.33333C6.38781 4.375 4 6.61358 4 9.375V22.5C4 25.2614 6.38781 27.5 9.33333 27.5H22.6667C25.6122 27.5 28 25.2614 28 22.5V9.375C28 6.61358 25.6122 4.375 22.6667 4.375H22.3327V2.5ZM10.6667 10.3125C10.1144 10.3125 9.66667 10.7322 9.66667 11.25C9.66667 11.7678 10.1144 12.1875 10.6667 12.1875H16C16.5523 12.1875 17 11.7678 17 11.25C17 10.7322 16.5523 10.3125 16 10.3125H10.6667ZM9.66667 16.25C9.66667 15.7322 10.1144 15.3125 10.6667 15.3125H21.3333C21.8856 15.3125 22.3333 15.7322 22.3333 16.25C22.3333 16.7678 21.8856 17.1875 21.3333 17.1875H10.6667C10.1144 17.1875 9.66667 16.7678 9.66667 16.25ZM10.6667 20.3125C10.1144 20.3125 9.66667 20.7322 9.66667 21.25C9.66667 21.7678 10.1144 22.1875 10.6667 22.1875H21.3333C21.8856 22.1875 22.3333 21.7678 22.3333 21.25C22.3333 20.7322 21.8856 20.3125 21.3333 20.3125H10.6667Z" fill="black"/>
                    </svg>
                    <br/>
                    게시물 작성
                </button>
        </div>
        {maketeam === true? 
            <div className={styles.make_team_box}>
                <button className={styles.team_close}
                onClick={ () => setMaketeam(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.08406 1.16985C1.69354 0.779322 1.06037 0.779322 0.669846 1.16985C0.279322 1.56037 0.279322 2.19354 0.669846 2.58406L4.96211 6.87632L0.669846 11.1686C0.279322 11.5591 0.279322 12.1923 0.669846 12.5828C1.06037 12.9733 1.69354 12.9733 2.08406 12.5828L6.37632 8.29053L10.6686 12.5828C11.0591 12.9733 11.6923 12.9733 12.0828 12.5828C12.4733 12.1923 12.4733 11.5591 12.0828 11.1686L7.79053 6.87632L12.0828 2.58408C12.4733 2.19356 12.4733 1.56039 12.0828 1.16987C11.6923 0.779343 11.0591 0.779343 10.6686 1.16987L6.37632 5.46211L2.08406 1.16985Z" fill="#969696"/>
                    </svg>
                </button>
                <Make_Team/>
            </div> 
        : null}
        {post === true?
            <div className={styles.make_team_box}>
                <button className={styles.team_close}
                onClick={ () => setPost(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.08406 1.16985C1.69354 0.779322 1.06037 0.779322 0.669846 1.16985C0.279322 1.56037 0.279322 2.19354 0.669846 2.58406L4.96211 6.87632L0.669846 11.1686C0.279322 11.5591 0.279322 12.1923 0.669846 12.5828C1.06037 12.9733 1.69354 12.9733 2.08406 12.5828L6.37632 8.29053L10.6686 12.5828C11.0591 12.9733 11.6923 12.9733 12.0828 12.5828C12.4733 12.1923 12.4733 11.5591 12.0828 11.1686L7.79053 6.87632L12.0828 2.58408C12.4733 2.19356 12.4733 1.56039 12.0828 1.16987C11.6923 0.779343 11.0591 0.779343 10.6686 1.16987L6.37632 5.46211L2.08406 1.16985Z" fill="#969696"/>
                    </svg>
                </button>
                <Make_Post/>
            </div>
        :null}
        </>
    );
}

export default Create;