import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Create from '../create/Create';
import navStyles from "../../css/component-css/navigation.module.css";


function Navigation(){

    //로그인되어야 네비게이션바 이용가능
    const [isLogin, setIsLogin] = useState(true);

    const handleSetIsLoginClick = ()=> {!isLogin ? setIsLogin(true) : setIsLogin(false)};

    const navigate = useNavigate();

    const goToLogin = ()=>{
        navigate("/loginbox");
    }

    const goToTeam = isLogin === true ? () => {
        navigate("/team");
      } : goToLogin;

      const goToChat = isLogin === true ? () => {
        navigate("/chat")
      } : goToLogin;
    
    const goToPost = isLogin === true ? () => {
        navigate("/post");
      } : goToLogin;

    const goToProfile = isLogin === true ? () => {
        navigate("/profile");
    } : goToLogin;


    const [create, setCreate] = useState(false);

    const [isNavTextVisible, setIsNavTextVisible] = useState(true);
    
    const updateNavText = () => {
        if (window.innerWidth <= 1280) {
            setIsNavTextVisible(false);
        } else if (window.innerWidth <= 1440) {
            setIsNavTextVisible(true);
        } else {
            setIsNavTextVisible(true);
        }
      };

    useEffect(() => {
        // 컴포넌트가 마운트되었을 때와 해상도가 변경될 때마다 호출하여 문구를 업데이트
        updateNavText();
        window.addEventListener('resize', updateNavText);
        return () => {
          window.removeEventListener('resize', updateNavText);
        };
      }, []);
    

    return (
        <div className={navStyles.nav}>
        <div className={navStyles.nav_box}>
        <div className={navStyles.nav_text_title_container}>
            <button 
            className={navStyles.logo}
            onClick={goToTeam}>
            <div className={navStyles.svg_container}>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="-50 0 600 450" fill="none">
                <path d="M416.658 0H83.3423C37.3136 0 0 37.3136 0 83.3423V416.658C0 462.686 37.3136 500 83.3423 500H416.658C462.686 500 500 462.686 500 416.658V83.3423C500 37.3136 462.686 0 416.658 0Z" fill="black"/>
                </svg>
            </div>
            <div className={navStyles.navTextTitle} style={{display: isNavTextVisible ? 'inline-block' : 'none'}}>SNACKS</div></button>
        </div>

        <ul className={navStyles.nav_ul}>
            <li className={navStyles.nav_li}>
                <button 
                className={navStyles.select}
                onClick={goToTeam}>
                    <div className={navStyles.svg_container}>                    
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="-6 -6 40 18" fill="none">
                        <path className={navStyles.select_img} d="M24.697 0H2.4697C1.11136 0 0 1.1736 0 2.608V13.04C0 14.4744 1.11136 15.648 2.4697 15.648H24.697C26.0553 15.648 27.1667 14.4744 27.1667 13.04V2.608C27.1667 1.1736 26.0553 0 24.697 0ZM12.3485 9.128H8.64394V13.04H6.17424V9.128H2.4697V6.52H6.17424V2.608H8.64394V6.52H12.3485V9.128ZM17.9053 11.736C16.8804 11.736 16.053 10.8623 16.053 9.78C16.053 8.69768 16.8804 7.824 17.9053 7.824C18.9302 7.824 19.7576 8.69768 19.7576 9.78C19.7576 10.8623 18.9302 11.736 17.9053 11.736ZM22.8447 7.824C21.8198 7.824 20.9924 6.95032 20.9924 5.868C20.9924 4.78568 21.8198 3.912 22.8447 3.912C23.8696 3.912 24.697 4.78568 24.697 5.868C24.697 6.95032 23.8696 7.824 22.8447 7.824Z" fill="black"/>
                        </svg>
                    </div>
                    <div className={navStyles.navText} style={{display: isNavTextVisible ? 'inline-block' : 'none'}}>팀 게시판</div>
                    </button></li>
            <li className={navStyles.nav_li}>
                <button 
                className={navStyles.select}
                onClick={goToChat}>
                    <div className={navStyles.svg_container}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="-8 -4 40 23" fill="none">
                        <path className={navStyles.select_img} fill-rule="evenodd" clip-rule="evenodd" d="M9.77778 0H11.1746C15.1289 0 18.4436 2.79565 19.3255 6.55642C19.4759 7.19776 19.5556 7.86717 19.5556 8.55556C19.5556 13.2807 15.8033 17.1111 11.1746 17.1111H6.0642H2.79365C1.25076 17.1111 0 15.8343 0 14.2593V9.98149C0 4.46886 4.37766 0 9.77778 0ZM11.5238 19.5556H7.40439C8.916 21.0677 10.9863 22 13.2698 22H18.3803H21.6508C23.1937 22 24.4445 20.7232 24.4445 19.1481V14.8704C24.4445 12.3369 23.5199 10.024 21.9964 8.26388C21.9988 8.3608 22 8.45802 22 8.55555C22 14.6307 17.3097 19.5556 11.5238 19.5556Z" fill="black"/>
                        </svg>
                    </div>
                    <div className={navStyles.navText} style={{display: isNavTextVisible ? 'inline-block' : 'none'}}>채팅</div>
                    </button>
            </li>
            <li className={navStyles.nav_li}>
                <button 
                className={navStyles.select}
                onClick={goToPost}>
                    <div className={navStyles.svg_container}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="-7 -2 45 25" fill="none">
                        <path className={navStyles.select_img} fill-rule="evenodd" clip-rule="evenodd" d="M22.3327 2.5C22.3327 1.98223 21.885 1.5625 21.3327 1.5625C20.7804 1.5625 20.3327 1.98223 20.3327 2.5V4.375H11.666V2.5C11.666 1.98223 11.2183 1.5625 10.666 1.5625C10.1137 1.5625 9.66602 1.98223 9.66602 2.5V4.375H9.33333C6.38781 4.375 4 6.61358 4 9.375V22.5C4 25.2614 6.38781 27.5 9.33333 27.5H22.6667C25.6122 27.5 28 25.2614 28 22.5V9.375C28 6.61358 25.6122 4.375 22.6667 4.375H22.3327V2.5ZM10.6667 10.3125C10.1144 10.3125 9.66667 10.7322 9.66667 11.25C9.66667 11.7678 10.1144 12.1875 10.6667 12.1875H16C16.5523 12.1875 17 11.7678 17 11.25C17 10.7322 16.5523 10.3125 16 10.3125H10.6667ZM9.66667 16.25C9.66667 15.7322 10.1144 15.3125 10.6667 15.3125H21.3333C21.8856 15.3125 22.3333 15.7322 22.3333 16.25C22.3333 16.7678 21.8856 17.1875 21.3333 17.1875H10.6667C10.1144 17.1875 9.66667 16.7678 9.66667 16.25ZM10.6667 20.3125C10.1144 20.3125 9.66667 20.7322 9.66667 21.25C9.66667 21.7678 10.1144 22.1875 10.6667 22.1875H21.3333C21.8856 22.1875 22.3333 21.7678 22.3333 21.25C22.3333 20.7322 21.8856 20.3125 21.3333 20.3125H10.6667Z" fill="black"/>
                        </svg>
                    </div>
                    <div className={navStyles.navText} style={{display: isNavTextVisible ? 'inline-block' : 'none'}}>게시물</div>
                    </button></li>
            <li className={navStyles.nav_li}>
                <button 
                className={navStyles.select}
                onClick={ 
                    isLogin === true ? () => {setCreate(true)} : goToLogin }>
                    <div className={navStyles.svg_container}>                    
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="-4 -5 38 31" fill="none">
                        <path className={navStyles.select_img} fill-rule="evenodd" clip-rule="evenodd" d="M27.5 15C27.5 21.9036 21.9036 27.5 15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15ZM15 9.0625C15.5178 9.0625 15.9375 9.48223 15.9375 10V14.0625H20C20.5178 14.0625 20.9375 14.4822 20.9375 15C20.9375 15.5178 20.5178 15.9375 20 15.9375H15.9375V20C15.9375 20.5178 15.5178 20.9375 15 20.9375C14.4822 20.9375 14.0625 20.5178 14.0625 20V15.9375H10C9.48223 15.9375 9.0625 15.5178 9.0625 15C9.0625 14.4822 9.48223 14.0625 10 14.0625H14.0625V10C14.0625 9.48223 14.4822 9.0625 15 9.0625Z" fill="black"/>
                        </svg>
                    </div>
                    <div className={navStyles.navText} style={{display: isNavTextVisible ? 'inline-block' : 'none'}}>만들기</div>
                    </button>
                {
                    create === true
                    ? 
                    <>
                    <div className={navStyles.create_box}>
                        <div className={navStyles.create}>    
                            <div className={navStyles.create_title}>만들기</div>
                            <button className={navStyles.close}
                            onClick={ () => setCreate(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.08406 1.16985C1.69354 0.779322 1.06037 0.779322 0.669846 1.16985C0.279322 1.56037 0.279322 2.19354 0.669846 2.58406L4.96211 6.87632L0.669846 11.1686C0.279322 11.5591 0.279322 12.1923 0.669846 12.5828C1.06037 12.9733 1.69354 12.9733 2.08406 12.5828L6.37632 8.29053L10.6686 12.5828C11.0591 12.9733 11.6923 12.9733 12.0828 12.5828C12.4733 12.1923 12.4733 11.5591 12.0828 11.1686L7.79053 6.87632L12.0828 2.58408C12.4733 2.19356 12.4733 1.56039 12.0828 1.16987C11.6923 0.779343 11.0591 0.779343 10.6686 1.16987L6.37632 5.46211L2.08406 1.16985Z" fill="#969696"/>
                            </svg>
                            </button>
                            
                            <Create/>
                        </div>
                    </div>
                    </>
                    :
                    null
                }
                

            </li>
            <li className={navStyles.nav_li}>
                <button 
                className={navStyles.select}
                onClick={goToProfile}>
                    <div className={navStyles.svg_container}>                    
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="-5 -5 35 29" fill="none">
                        <path className={navStyles.select_img} d="M12.5 0C5.6 0 0 5.6 0 12.5C0 19.4 5.6 25 12.5 25C19.4 25 25 19.4 25 12.5C25 5.6 19.4 0 12.5 0ZM12.5 3.75C14.575 3.75 16.25 5.425 16.25 7.5C16.25 9.575 14.575 11.25 12.5 11.25C10.425 11.25 8.75 9.575 8.75 7.5C8.75 5.425 10.425 3.75 12.5 3.75ZM12.5 21.5C9.375 21.5 6.6125 19.9 5 17.475C5.0375 14.9875 10 13.625 12.5 13.625C14.9875 13.625 19.9625 14.9875 20 17.475C18.3875 19.9 15.625 21.5 12.5 21.5Z" fill="black"/>
                        </svg>
                    </div>
                    <div className={navStyles.navText} style={{display: isNavTextVisible ? 'inline-block' : 'none'}}>프로필</div>
                    </button></li>
        </ul>
        {/* //로그인 on off */}
        <div style={{display: 'inline'}}><button onClick={handleSetIsLoginClick}>로</button></div>
        </div>
        <div className={navStyles.navLine}></div>
        </div>
    )
}

export default Navigation;