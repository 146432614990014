import ChattingContainertStyles from '../../css/chat-css/ChattingCotent.module.css';


function ChattingContainer(){
    return (
        <>
        <div className={ChattingContainertStyles.chatting_container}>
            <div className={ChattingContainertStyles.chatting_container_header}>
                <button className={ChattingContainertStyles.chatting_container_header_setting}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0455 3H12.9545C14.0089 3 14.8636 3.80589 14.8636 4.8C14.8636 5.93762 16.0808 6.66088 17.08 6.11698L17.1815 6.06174C18.0946 5.56468 19.2622 5.85966 19.7894 6.72058L20.7439 8.27943C21.2711 9.14036 20.9582 10.2412 20.0451 10.7383C19.0455 11.2824 19.0455 12.7176 20.0451 13.2617C20.9582 13.7588 21.2711 14.8596 20.7439 15.7206L19.7894 17.2794C19.2622 18.1403 18.0946 18.4353 17.1815 17.9383L17.08 17.883C16.0808 17.3391 14.8636 18.0624 14.8636 19.2C14.8636 20.1941 14.0089 21 12.9545 21H11.0455C9.99109 21 9.13635 20.1941 9.13635 19.2C9.13635 18.0624 7.91917 17.3391 6.92 17.883L6.81851 17.9383C5.90541 18.4353 4.73782 18.1404 4.21064 17.2794L3.25609 15.7206C2.72891 14.8597 3.04176 13.7588 3.95487 13.2617C4.95451 12.7176 4.95451 11.2824 3.95487 10.7383C3.04176 10.2412 2.72891 9.14034 3.25609 8.27942L4.21064 6.72057C4.73782 5.85964 5.90541 5.56467 6.81852 6.06172L6.92 6.11697C7.91917 6.66087 9.13635 5.93761 9.13635 4.8C9.13635 3.80589 9.99109 3 11.0455 3ZM12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3432 9 9.00001 10.3431 9.00001 12C9.00001 13.6569 10.3432 15 12 15Z" fill="black"/>
                    </svg>
                </button>
                <div className={ChattingContainertStyles.chatting_container_header_room_title}>네디</div>
                <button className={ChattingContainertStyles.chatting_container_header_close}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path d="M1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L4.58515 5.99937L0.292893 10.2916C-0.0976311 10.6822 -0.0976311 11.3153 0.292893 11.7058C0.683417 12.0964 1.31658 12.0964 1.70711 11.7058L5.99937 7.41358L10.2916 11.7058C10.6821 12.0963 11.3153 12.0963 11.7058 11.7058C12.0963 11.3153 12.0963 10.6821 11.7058 10.2916L7.41358 5.99937L11.7058 1.70713C12.0963 1.3166 12.0963 0.683438 11.7058 0.292914C11.3153 -0.0976105 10.6821 -0.0976105 10.2916 0.292914L5.99937 4.58515L1.70711 0.292893Z" fill="black"/>
                    </svg>
                </button>
            </div>
            <div className={ChattingContainertStyles.chatting_container_main}>

            </div>
            <div className={ChattingContainertStyles.chatting_container_send_box}>
                <div className={ChattingContainertStyles.chatting_container_send_box_btns}>
                    <button className={ChattingContainertStyles.chatting_container_send_box_btns_send}>전송</button>
                </div>
                <div className={ChattingContainertStyles.chatting_container_send_box_content}>
                    <textarea type="text" className={ChattingContainertStyles.chatting_container_send_box_input}></textarea>
                </div>
            </div>
        </div>
        </>
    );
}


export default ChattingContainer;
