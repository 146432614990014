import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Snacks from './component/SnacksPage';
import Login from './component/login/Login';
import Agree from './component/signup/signupPageAgree';
import Signup_user from './component/signup/Membership';
import SignupComplete from './component/signup/SignupComplete';

function App(){
    return(
    <>
        <BrowserRouter>
            <Routes>
                <Route path="/*" element={<Snacks />} />
                <Route path="/loginbox" element={<Login />} />
                <Route path="/signup">
                    <Route index path="agree" element={<Agree/>}/>
                    <Route index path="user" element={<Signup_user/>} />
                    <Route index path="complete" element={<SignupComplete/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    </>
    );
    
};

export default App;