import TeamInfoBoxMasterStyles from '../../css/team-css/TeamInfoBoxMaster.module.css';
import TeamInfoBoxProfileAlready from './TeamInfoBoxProfileAlready';
import TeamInfoBoxProfileReady from './TeamInfoBoxProfileReady';
import {useState} from 'react';


function TeamInfoBoxMaster({teamInfo, onClick}) {
    const [selectReadyList, setSelectReadyList] = useState(false);
    const handleSelectAlreadyListClick = () => {setSelectReadyList(false)};
    const handleSelectReadyListClick = () => {setSelectReadyList(true)};
    return (
        <div className={TeamInfoBoxMasterStyles.team_modal_back}>
            <div className={TeamInfoBoxMasterStyles.team_modal}>
                <div className={TeamInfoBoxMasterStyles.team_modal_up_box}>
                    <div className={TeamInfoBoxMasterStyles.team_modal_setting}>⚙️</div>
                    <div className={TeamInfoBoxMasterStyles.team_modal_team_title}></div>
                    <div className={TeamInfoBoxMasterStyles.team_modal_close} onClick={onClick}>❌</div>
                </div>
                <div className={TeamInfoBoxMasterStyles.team_modal_down_box}>
                    <div className={TeamInfoBoxMasterStyles.team_modal_down_box_team_info}>
                        <div className={TeamInfoBoxMasterStyles.team_modal_down_box_team_info_important}>
                            <div className={TeamInfoBoxMasterStyles.team_modal_game_image_box}></div>
                            <div className={TeamInfoBoxMasterStyles.team_modal_down_box_team_info_very_important}>
                                <div className={TeamInfoBoxMasterStyles.team_modal_down_box_team_info_very_important_title}>{teamInfo.teamBoxTitle}</div>
                                <div className={TeamInfoBoxMasterStyles.team_modal_down_box_team_info_very_important_datetime_box}>
                                    <div className={TeamInfoBoxMasterStyles.team_modal_down_box_team_info_very_important_date}>{teamInfo.teamDate}</div>
                                    <div className={TeamInfoBoxMasterStyles.team_modal_down_box_team_info_very_important_time}>{teamInfo.teamTime}</div>
                                </div>
                                <div className={TeamInfoBoxMasterStyles.team_modal_down_box_team_info_very_important_game_title_member_num_box}>
                                    <div className={TeamInfoBoxMasterStyles.team_modal_down_box_team_info_very_important_game_title}>{teamInfo.gameTitle}</div>
                                    <div className={TeamInfoBoxMasterStyles.team_modal_down_box_team_info_very_important_slash}>|</div>
                                    <div className={TeamInfoBoxMasterStyles.team_modal_down_box_team_info_very_important_member_num}>최대인원 {teamInfo.teamMemberNum}</div>
                                </div>
                            </div>
                        </div>
                        <div className={TeamInfoBoxMasterStyles.team_modal_down_box_team_info_notice}>
                        안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안안
                        </div>
                    </div>
                    <div className={TeamInfoBoxMasterStyles.team_modal_down_box_team_member_info}>
                        <div className={TeamInfoBoxMasterStyles.team_modal_down_box_btns}>
                            <div className={TeamInfoBoxMasterStyles.team_modal_down_box_team_member_info_title} onClick={handleSelectAlreadyListClick}>참가인원</div>
                            <div className={TeamInfoBoxMasterStyles.team_modal_down_box_team_member_ready} onClick={handleSelectReadyListClick}>신청대기</div>
                        </div>
                        <div className={TeamInfoBoxMasterStyles.team_modal_down_box_team_member_info_list}>
                            {
                                !selectReadyList?<TeamInfoBoxProfileAlready/>:<TeamInfoBoxProfileReady/>
                            }
                        </div>
                    </div>
                    <div className={TeamInfoBoxMasterStyles.team_modal_team_info_button_container}>
                        <button className={TeamInfoBoxMasterStyles.team_modal_submit}>참가신청</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TeamInfoBoxMaster;