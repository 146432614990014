import LeftPrivateChatListStyles from '../../css/chat-css/LeftPrivateChatList.module.css';


function LeftPrivateChatList({chatList,onClick}){
    return (
        <div className= {LeftPrivateChatListStyles.left_private_chat_list}>
            {
            chatList.map((chatRooom)=>{
                return <LeftPrivateChat chatRoom = {chatRooom} onClick = {onClick}/>
            })}
            
        </div>
    );
}

function LeftPrivateChat({chatRoom, onClick}){
    return (
        <div className={LeftPrivateChatListStyles.left_private_chat_box} onClick = {onClick}>
            <div className={LeftPrivateChatListStyles.left_private_chat_box_img_box}>
                <img src={chatRoom.imageUrl} alt="profile-img" className={LeftPrivateChatListStyles.left_private_chat_box_img} />
            </div>
            <div className={LeftPrivateChatListStyles.left_private_chat_box_reading_num}>{chatRoom.numberOfUnreadMessage}</div>
        </div>
    );
}



export default LeftPrivateChatList;