import ChatPageStyles from '../../css/chat-css/ChatPage.module.css';
import { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import ChattingContainer from './ChattingContainer';
import TeamChat from './TeamChat';
import LeftPrivateChatList from './LeftPrivateChat';
import RightPrivateChatList from './RightPrivateChat';
import LeftTeamChatList from './LeftTeamChat';
import RightTeamChatList from './RightTeamChat';

function ChatPage({chatList}){

    const [teamChatList, setTeamChatList]=useState(chatList);
    const [privateChatList, setPrivateChatList]=useState(chatList);

    const handleTeamChatList = ()=>{setTeamChatList(chatList.filter((el)=>el.type === "team"));};
    const handlePrivateChatList = ()=>{setPrivateChatList(chatList.filter((el)=>el.type === 'private'));};
    const handleTeamChatListOrder = ()=>{ setTeamChatList(teamChatList.sort((a, b)=> new Date(b.time)- new Date(a.time)))};
    const handlePrivateChatListOrder = ()=>{ setPrivateChatList(privateChatList.sort((a, b)=> new Date(b.time)- new Date(a.time)))};

    useEffect(()=>{

        //이거 너무 빨리 실행되서 데이터를 정렬을 못함, 다른 방법써야 함.
        handleTeamChatList();
        handlePrivateChatList();
        handleTeamChatListOrder();
        handlePrivateChatListOrder();

        handleTeamChatList();
        handlePrivateChatList();
        handleTeamChatListOrder();
        handlePrivateChatListOrder();

        handleTeamChatList();
        handlePrivateChatList();

    },[]);

    const navigate = useNavigate();

    const [selectTeamOrPrivateChat, setSelectTeamOrPrivateChat] = useState('Team');

    const handleSelectTeamChat = ()=>{
        setSelectTeamOrPrivateChat('Team');
        navigate("/chat/team");
    };

    const handleSelectPrivateChat = ()=>{
        setSelectTeamOrPrivateChat('Chat');
        navigate("/chat/private");
    };

    const selectTeamOrPrivateChatOnClick = selectTeamOrPrivateChat === 'Team' ? handleSelectPrivateChat : handleSelectTeamChat;

    return (
    <div className={ChatPageStyles.chat_page}>
        
        <div className={ChatPageStyles.chat_page_container}>
            <div className={ChatPageStyles.chat_list_container}>
                <div className={ChatPageStyles.left_chat_list}>
                    <div className={ChatPageStyles.left_chat_list_title}>{selectTeamOrPrivateChat === 'Team' ? 'Chat' : 'Team'}</div>
                    {
                        selectTeamOrPrivateChat === 'Team' ? <LeftPrivateChatList chatList={privateChatList} onClick={selectTeamOrPrivateChatOnClick} /> : <LeftTeamChatList chatList={teamChatList} onClick={selectTeamOrPrivateChatOnClick} />
                    }
                </div>
                <div className={ChatPageStyles.right_chat_list}>
                    <div className={ChatPageStyles.right_chat_list_title}>{selectTeamOrPrivateChat}</div>
                    {
                        selectTeamOrPrivateChat === 'Team' ? <RightTeamChatList chatList={teamChatList} /> : <RightPrivateChatList chatList={privateChatList} />
                    }
                </div>
            </div>
            <div className={ChatPageStyles.chat_page_chatting}>
                <ChattingContainer />
            </div>
        </div>
        
    </div>
    );
}

export default ChatPage;


