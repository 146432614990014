import React from "react";
import styles from "../../css/left_side.module.css"
import { useState } from "react";

import Image from "./image_box";
import Video from "./video_box";
import Vote from "./vote_box";

function Make_Post(){
    const [image, setImage] = useState(false);
    const [video, setVideo] = useState(false);
    const [vote, setVote] = useState(false);

    return(
        <>
            <div className={styles.make_team}>
                    <svg style={{float: "left", marginTop: "2%", marginLeft: "2.5%"}} xmlns="http://www.w3.org/2000/svg" width="27" height="25" viewBox="0 0 27 25" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.8432 2.04549C18.8432 1.62186 18.4654 1.27844 17.9995 1.27844C17.5335 1.27844 17.1557 1.62186 17.1557 2.04549V3.57958H9.8432V2.04549C9.8432 1.62186 9.46544 1.27844 8.99945 1.27844C8.53346 1.27844 8.1557 1.62186 8.1557 2.04549V3.57958H7.875C5.38972 3.57958 3.375 5.41114 3.375 7.67049V18.4091C3.375 20.6685 5.38972 22.5 7.875 22.5H19.125C21.6103 22.5 23.625 20.6685 23.625 18.4091V7.67049C23.625 5.41114 21.6103 3.57958 19.125 3.57958H18.8432V2.04549ZM9 8.43753C8.53401 8.43753 8.15625 8.78095 8.15625 9.20458C8.15625 9.62821 8.53401 9.97163 9 9.97163H13.5C13.966 9.97163 14.3438 9.62821 14.3438 9.20458C14.3438 8.78095 13.966 8.43753 13.5 8.43753H9ZM8.15625 13.2955C8.15625 12.8719 8.53401 12.5284 9 12.5284H18C18.466 12.5284 18.8438 12.8719 18.8438 13.2955C18.8438 13.7191 18.466 14.0625 18 14.0625H9C8.53401 14.0625 8.15625 13.7191 8.15625 13.2955ZM9 16.6194C8.53401 16.6194 8.15625 16.9628 8.15625 17.3864C8.15625 17.81 8.53401 18.1534 9 18.1534H18C18.466 18.1534 18.8438 17.81 18.8438 17.3864C18.8438 16.9628 18.466 16.6194 18 16.6194H9Z" fill="black"/>
                    </svg>
                    <p style={{float:"left", marginLeft:"1%", marginTop: "2.7%"}}>
                        게시물 작성
                    </p>
            </div>
            <div className={styles.post_selects}>
                <button 
                className={styles.post_select}
                onClick={() => {setImage(!image)}}>
                    Image</button>
                <button 
                className={styles.post_select}
                onClick={() => {setVideo(!video)}}>
                    Video</button>
                <button className={styles.post_select}
                onClick={() => {setVote(!vote)}}>
                    Vote</button>
                {image === true?
                <div 
                className={styles.image_box}>
                    <button 
                    className={styles.close_img}
                    onClick={() => {setImage(false)}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                    <path d="M12.1205 2.12069C12.5111 1.73016 12.5111 1.097 12.1205 0.706474C11.73 0.31595 11.0968 0.31595 10.7063 0.706474L6.41406 4.99874L2.1218 0.706474C1.73128 0.31595 1.09811 0.31595 0.707588 0.706474C0.317063 1.097 0.317064 1.73016 0.707588 2.12069L4.99985 6.41295L0.70761 10.7052C0.317085 11.0957 0.317084 11.7289 0.707609 12.1194C1.09813 12.5099 1.7313 12.5099 2.12182 12.1194L6.41406 7.82716L10.7063 12.1194C11.0968 12.5099 11.73 12.5099 12.1205 12.1194C12.511 11.7289 12.511 11.0957 12.1205 10.7052L7.82828 6.41295L12.1205 2.12069Z" fill="#FA5B5B"/>
                    </svg>
                    </button>
                    <Image/>
                </div>     
                :null}
                {video === true?
                <div 
                className={styles.video_box}>
                    <button 
                    className={styles.close_img}
                    onClick={() => {setVideo(false)}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                    <path d="M12.1205 2.12069C12.5111 1.73016 12.5111 1.097 12.1205 0.706474C11.73 0.31595 11.0968 0.31595 10.7063 0.706474L6.41406 4.99874L2.1218 0.706474C1.73128 0.31595 1.09811 0.31595 0.707588 0.706474C0.317063 1.097 0.317064 1.73016 0.707588 2.12069L4.99985 6.41295L0.70761 10.7052C0.317085 11.0957 0.317084 11.7289 0.707609 12.1194C1.09813 12.5099 1.7313 12.5099 2.12182 12.1194L6.41406 7.82716L10.7063 12.1194C11.0968 12.5099 11.73 12.5099 12.1205 12.1194C12.511 11.7289 12.511 11.0957 12.1205 10.7052L7.82828 6.41295L12.1205 2.12069Z" fill="#FA5B5B"/>
                    </svg>
                    </button>
                    <Video/>
                </div> 
                :null}
                {vote === true?
                 <div 
                 className={styles.vote_box}>
                     <button 
                     className={styles.close_img}
                     onClick={() => {setVote(false)}}>
                     <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                     <path d="M12.1205 2.12069C12.5111 1.73016 12.5111 1.097 12.1205 0.706474C11.73 0.31595 11.0968 0.31595 10.7063 0.706474L6.41406 4.99874L2.1218 0.706474C1.73128 0.31595 1.09811 0.31595 0.707588 0.706474C0.317063 1.097 0.317064 1.73016 0.707588 2.12069L4.99985 6.41295L0.70761 10.7052C0.317085 11.0957 0.317084 11.7289 0.707609 12.1194C1.09813 12.5099 1.7313 12.5099 2.12182 12.1194L6.41406 7.82716L10.7063 12.1194C11.0968 12.5099 11.73 12.5099 12.1205 12.1194C12.511 11.7289 12.511 11.0957 12.1205 10.7052L7.82828 6.41295L12.1205 2.12069Z" fill="#FA5B5B"/>
                     </svg>
                     </button>
                     <Vote/>
                </div>
                :null}
            </div>
            <div className={styles.line}></div>
            <div>
                <textarea className={styles.post_input}></textarea>
            </div>
            <button className={styles.post_button}>작성완료</button>
            

        
        </>
    );
}

export default Make_Post;