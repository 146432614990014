import RightTeamChatListStyles from '../../css/chat-css/RightTeamChatList.module.css';


function RightTeamChatList({chatList}){
    return (
        <div className= {RightTeamChatListStyles.right_team_chatList}>
            <div className={RightTeamChatListStyles.right_team_search_box}>
                <input type="text" className={RightTeamChatListStyles.right_team_search_input}/>
                <button className={RightTeamChatListStyles.right_team_search_btn}>🔍</button>
            </div>
            <div className={RightTeamChatListStyles.right_team_chat_box_container}>
                {
                chatList.map((chatRooom)=>{
                    return <RightTeamChat chatRoom = {chatRooom}/>
                })}
            </div>
        </div>
    );
}

function RightTeamChat({chatRoom}){
    return (
        <div className={RightTeamChatListStyles.right_team_chat_box}>
            <div className={RightTeamChatListStyles.right_team_chat_box_left_box}>
                <img src={chatRoom.imageUri} alt="" className={RightTeamChatListStyles.right_team_chat_box_left_box_img} />
            </div>
            <div className={RightTeamChatListStyles.right_team_chat_box_right_box}>
                <div className={RightTeamChatListStyles.right_team_chat_box_right_box_title}>{chatRoom.name}</div>
                <div className={RightTeamChatListStyles.right_team_chat_box_right_box_datetime_box}>예정된 게임<div>2023.07.02</div><div>오후 3:00</div></div>
                <div className={RightTeamChatListStyles.right_team_chat_box_right_box_last_chat}>{chatRoom.content}</div>
                <div className={RightTeamChatListStyles.right_team_chat_box_alram_icon}>⏰</div>
                <div className={RightTeamChatListStyles.right_team_chat_box_reading_num}>{chatRoom.numberOfUnreadMessage}</div>
            </div>
        </div>
    );
}

export default RightTeamChatList;