import teamboxStyles from '../../css/team-css/TeamBox.module.css';
import {useState} from 'react';
import TeamInfoBox from './TeamInfoBox';
import TeamInfoBoxMaster from './TeamInfoBoxMaster';


function TeamBoxList({items}) {
  
  return (
    <div className={teamboxStyles.team_page_main_box}>
      {
        items.map((item)=>{
          return <div className={teamboxStyles.team_box_container} ><TeamBox item={item} /></div>
        })
      }

      
    </div>
  );
}

function TeamBox({item}) {
  const [teamInfoBoxModal, setTeamInfoBoxModal] = useState(false);
  const handleTeamInfoBoxModalOff = () => setTeamInfoBoxModal(false);
  const [isTeamMaster, setIsTeamMaster] = useState(false);

  const handleTeamBoxClick = () => {
    setTeamInfoBoxModal(true);
  };

  const handleIsTeamMasterOn = () => {
    setIsTeamMaster(true);

  }

  const handleIsTeamMasterOff = () => {
    setIsTeamMaster(false);

  }

  console.log(item);
  
  return (
    <>
      <div className={teamboxStyles.team_box} onClick={handleTeamBoxClick}>
        <div className={teamboxStyles.team_box_content}>
          <div className={teamboxStyles.team_left_box}>
            <img className={teamboxStyles.team_game_img} src='https://play-lh.googleusercontent.com/VSwHQjcAttxsLE47RuS4PqpC4LT7lCoSjE7Hx5AW_yCxtDvcnsHHvm5CTuL5BPN-uRTP=s96-rw' alt='team-game-img' />
          </div>
          <div className={teamboxStyles.team_right_box}>
            <div className={teamboxStyles.team_box_title}>{item.teamBoxTitle}</div>
            <div className={teamboxStyles.team_game_title}>{item.gameTitle}</div>
            <div className={teamboxStyles.team_date_info}>
              <div className={teamboxStyles.team_date}>{item.teamDate}</div>
              <div className={teamboxStyles.team_time}>{item.teamTime}</div>
            </div>
            <div className={teamboxStyles.team_box_profile_group}></div>
            <div className={teamboxStyles.team_member_num}>{item.teamMemberNum}</div>
          </div>
        </div>
      </div>
        {
          teamInfoBoxModal === true && !isTeamMaster? <TeamInfoBox teamInfo={item} onClick={handleTeamInfoBoxModalOff}/> : true && isTeamMaster ? <TeamInfoBoxMaster teamInfo={item} onClick={handleTeamInfoBoxModalOff}/> : null
        }
      </>
  );
}

export default TeamBoxList;
