import React from "react";
import styles from "../../css/signup-css/Membership.module.css"
import { useState } from "react";
import { useCallback } from "react";
import { Link } from "react-router-dom";

function Signup_user() {

  const [userInfo, setUserInfo] = useState({
    name: "",
    id: "",
    passwd: "",
    birth: "",
    nickname: "",
  });

  const [pwType, setpwType] = useState({
    type: "password",
    visible: false,
  });
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  
   //오류메시지 상태저장
   const [emailMessage, setEmailMessage] = useState('')
   const [idCheckMessage, setIdCheckMessage] = useState('')
   const [passwordMessage, setPasswordMessage] = useState('')
   const [passwordConfirmMessage, setPasswordConfirmMessage] = useState('')
   // 유효성 검사
   const [isEmail, setIsEmail] = useState(false)
   const [idCheck, setIdCheck] = useState(false)
   const [isPassword, setIsPassword] = useState(false)
   const [isPasswordConfirm, setIsPasswordConfirm] = useState(false)

  const handlePasswordType = (e) => {
    setpwType(() => {
      if (!pwType.visible) {
        return { type: "text", visible: true };
      } else {
        return { type: "password", visible: false };
      }
    });
  };

  const onChangeEmail = useCallback((e) => { //id 유효성 검사
    const emailRegex =
    /^[a-zA-z0-9]{5,12}$/ 
    const emailCurrent = e.target.value
    setEmail(emailCurrent)

    if (!emailRegex.test(emailCurrent)) {
      setEmailMessage('5자 이내, 영어 소문자, 숫자를 입력해주세요.')
      setIsEmail(false)
    } else { 
      setEmailMessage('')
      setIsEmail(true)
    }
  }, [])

  const onCheckID = 1;

// blank
  // const onCheckID = useCallback((e) => { //axios로 중복확인 ==> 백엔드
  //   axios
  //   .get(/member/save/id?id=${user_ID})
  //   .then((res) => {
  //     setIdCheck(res.data)
  //     //boolean 값으로 받기
  //     if (){ //중복 아님
  //       alert("사용 가능한 ID입니다.")
  //     }
  //     else{
  //       alert("사용 가능하지 않은 ID입니다.")
  //     } 
  //   } 
  // })

  // 비밀번호
  const onChangePassword = useCallback((e) => {
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/
    const passwordCurrent = e.target.value
    setPassword(passwordCurrent)

    if (!passwordRegex.test(passwordCurrent)) {
      setPasswordMessage('8~16자, 영문, 숫자, 특수 기호를 포함하여야 합니다.')
      setIsPassword(false)
    } else {
      setPasswordMessage(' ')
      setIsPassword(true)
    }
  }, [])

  // 비밀번호 확인
  const onChangePasswordConfirm = useCallback(
    (e) => {
      const passwordConfirmCurrent = e.target.value
      setPasswordConfirm(passwordConfirmCurrent)

      if (password === passwordConfirmCurrent) {
        setPasswordConfirmMessage('일치')
        setIsPasswordConfirm(true)
      } else {
        setPasswordConfirmMessage('불일치')
        setIsPasswordConfirm(false)
      }
    },
    [password]
  )
  return (
  <>
  <div className={styles.container}>
    <h2 className={styles.logo}>SNACKS</h2>
    <div className={styles.form}>

    <label className={styles.label}>성명</label>
    <div className={styles.input_position}><input className={styles.input} /></div>
    <label className={styles.label}>아이디</label>  
    <div className={styles.input_position}>
      <input className={styles.input} required minLength={5} onChange={onChangeEmail}></input>
      {email.length > 0 && <span className={`message ${isEmail ? 'success' : 'error'}` && styles.message}>{emailMessage}</span>}
      <p className={styles.duplicate}>{isEmail === true? 
      <button className = {styles.duplicate_text} 
        style={{ //버튼 css ==> id 유효성 검사 성공시 빨간색으로 바뀜.
        color: "#FA5B5B",
        fontFamily: "Inter",
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: 300,
        lineHeight: "normal",
        cursor: "pointer"
       }}
       onClick={onCheckID}>
        중복확인</button> : <p>중복확인</p>}</p>
    </div>
    <label className={styles.label}>비밀번호</label>
    <div className={styles.input_position}> 
      <input type={pwType.type} 
      required minLength={8} maxLength={16}
      onChange={onChangePassword}
      className={styles.input}/> 
      {password.length > 0 && (
        <span className={`message ${isPassword ? 'success' : 'error'}` && styles.message}>{passwordMessage}</span>
      )}
      <button onClick={handlePasswordType} 
      className={styles.passwordview}>
        {pwType.visible ? 
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.46967 4.53033C3.17678 4.23744 3.17678 3.76256 3.46967 3.46967C3.76256 3.17678 4.23744 3.17678 4.53033 3.46967L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303L16.6429 17.7036C15.2337 18.4709 13.66 19 12 19C8.18448 19 4.82549 16.2047 2.86971 14.1469C1.7101 12.9268 1.7101 11.0732 2.86971 9.8531C3.69953 8.98001 4.78196 7.97414 6.04468 7.10534L3.46967 4.53033ZM9.41536 10.476C9.15145 10.9227 9 11.4436 9 12C9 13.6569 10.3431 15 12 15C12.5564 15 13.0773 14.8486 13.524 14.5846L9.41536 10.476ZM12 5C15.8155 5 19.1745 7.79533 21.1303 9.8531C22.2899 11.0732 22.2899 12.9268 21.1303 14.1469C20.6902 14.6099 20.1791 15.1103 19.6078 15.6077L9.4127 5.41264C10.2422 5.15256 11.1086 5 12 5Z" fill="#28303F"/></svg>
        : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_27_204)">
              <path d="M12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 17C9.24 17 7 14.76 7 12C7 9.24 9.24 7 12 7C14.76 7 17 9.24 17 12C17 14.76 14.76 17 12 17ZM12 9C10.34 9 9 10.34 9 12C9 13.66 10.34 15 12 15C13.66 15 15 13.66 15 12C15 10.34 13.66 9 12 9Z" fill="#191C1D"/>
            </g>
            <defs>
              <clipPath id="clip0_27_204">
                <rect width="24" height="24" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        }

      </button>
    </div>
    <label className={styles.label}>비밀번호 확인</label>
    <div className={styles.input_position}>
      <input type={pwType.type}
      required minLength={8} maxLength={16} 
      onChange={onChangePasswordConfirm}
      className={styles.input} />
      {passwordConfirm.length > 0 && (
        <span className={`message ${isPasswordConfirm ? 'success' : 'error'}` 
        && styles.check}>{passwordConfirmMessage}</span>
      )}
    </div>
    <label className={styles.label}>생년월일</label>
    <div className={styles.input_position}>
      <input className={styles.input_birth} 
      placeholder="생년월일 8자리"
      required maxLength={8}
      type="date" /></div>
    <label className={styles.label}>닉네임</label>
    <div className={styles.input_position}>
      <input className={styles.input}
      required maxLength={10} /></div>
    
    </div>

      <div className={styles.button_position}>
        <Link to='/signup/complete'><button className={styles.button} type="submit">다음</button></Link>
      </div>
  </div>
  </>
  );
}

export default Signup_user;
