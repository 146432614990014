import React from "react";
import styles from "../../css/left_side.module.css"

function Post(){
    return(
        <>
        
         <div className={styles.post}>게시물</div>
        </>
    );
}

export default Post;