import React from "react";
import styles from "../../css/left_side.module.css"
import { useState } from "react";

import Game_Select from "./game_select";

function Make_Team(){
    const [game, setGame] = useState(false);
    return(
        <>
            <div className={styles.make_team}>
                    <svg style={{float: "left", marginTop: "3%", marginLeft: "3%"}} xmlns="http://www.w3.org/2000/svg" width="28" height="16" viewBox="0 0 28 16" fill="none">
                    <path d="M24.697 0H2.4697C1.11136 0 0 1.1736 0 2.608V13.04C0 14.4744 1.11136 15.648 2.4697 15.648H24.697C26.0553 15.648 27.1667 14.4744 27.1667 13.04V2.608C27.1667 1.1736 26.0553 0 24.697 0ZM12.3485 9.128H8.64394V13.04H6.17424V9.128H2.4697V6.52H6.17424V2.608H8.64394V6.52H12.3485V9.128ZM17.9053 11.736C16.8804 11.736 16.053 10.8623 16.053 9.78C16.053 8.69768 16.8804 7.824 17.9053 7.824C18.9302 7.824 19.7576 8.69768 19.7576 9.78C19.7576 10.8623 18.9302 11.736 17.9053 11.736ZM22.8447 7.824C21.8198 7.824 20.9924 6.95032 20.9924 5.868C20.9924 4.78568 21.8198 3.912 22.8447 3.912C23.8696 3.912 24.697 4.78568 24.697 5.868C24.697 6.95032 23.8696 7.824 22.8447 7.824Z" fill="black"/>
                    </svg>
                    <p style={{float:"left", marginLeft:"1.5%", marginTop: "2.7%"}}>
                        팀 생성
                    </p>
            </div>
            <div className={styles.team_parent}> 
                <button 
                className={styles.game_select}
                onClick={() => {setGame(!game)}}>
                    게임선택
                </button>
                {game === true?
                <Game_Select/>
                :null}
                <input className={styles.title}
                    placeholder="제목을 입력해주세요."></input>
                <br/><br/>
                <div>
                    <input className={styles.option} type="number" placeholder="숫자 입력">
                    </input>
                    <input className={styles.option} type="date" placeholder="날짜 선택">
                    </input>
                    <input className={styles.option} type="time" placeholder="시간 선택">
                    </input>
                </div>
                <div styles={{paddingRight: "10%"}} >
                   <button className={styles.checkbox_left}>
                    참가 신청 자동 수락
                    <svg className={styles.check} xmlns="http://www.w3.org/2000/svg" width="15" height="21" viewBox="0 -5 18 14" fill="none">
                    <path d="M10.9425 4.185L6 9.1275L3.3075 6.4425L2.25 7.5L6 11.25L12 5.25L10.9425 4.185ZM7.5 0C3.36 0 0 3.36 0 7.5C0 11.64 3.36 15 7.5 15C11.64 15 15 11.64 15 7.5C15 3.36 11.64 0 7.5 0ZM7.5 13.5C4.185 13.5 1.5 10.815 1.5 7.5C1.5 4.185 4.185 1.5 7.5 1.5C10.815 1.5 13.5 4.185 13.5 7.5C13.5 10.815 10.815 13.5 7.5 13.5Z" fill="#969696"/>
                    </svg>
                   </button>
                   <button className={styles.checkbox_right}>
                    30분간 실시간 모집
                    <svg className={styles.check} xmlns="http://www.w3.org/2000/svg" width="15" height="21" viewBox="0 -5 18 14" fill="none">
                    <path d="M10.9425 4.185L6 9.1275L3.3075 6.4425L2.25 7.5L6 11.25L12 5.25L10.9425 4.185ZM7.5 0C3.36 0 0 3.36 0 7.5C0 11.64 3.36 15 7.5 15C11.64 15 15 11.64 15 7.5C15 3.36 11.64 0 7.5 0ZM7.5 13.5C4.185 13.5 1.5 10.815 1.5 7.5C1.5 4.185 4.185 1.5 7.5 1.5C10.815 1.5 13.5 4.185 13.5 7.5C13.5 10.815 10.815 13.5 7.5 13.5Z" fill="#969696"/>
                    </svg>
                   </button>
                </div>
                <br/>
                <div style={{marginTop: "-3%"}}>
                    <span style={{
                        float: "left", 
                        marginLeft: "8%", 
                        marginTop: "-0.5%",
                        fonFamily: "Inter",
                        fontSize: "15px",
                        fontStyle: "italic",
                        fontWeight: "600",
                        lineHeight: "normal"
                    }}>
                        Notice
                    </span>
                    <br/>
                    <textarea className={styles.notice} type="text"></textarea>
                </div>
                <div>
                    <button className={styles.invite}>친구 초대하기</button>
                    <button className={styles.create_button}>생성</button>
                </div>
            </div>
        </>
    );
}

export default Make_Team;