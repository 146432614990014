import React from "react";
import styles from "../../css/left_side.module.css"

function Vote(){

    return(
    
        <div >
            <div className={styles.title_position}>
                <input className={styles.vote_title} placeholder="제목 입력."></input>
            </div>
            <div className={styles.votes}>
                <div className={styles.A}>

                <div className={styles.copy}>A</div>
                <div className={styles.vote_plus}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path d="M9.99986 1.92969C9.99986 1.3774 9.55215 0.929687 8.99986 0.929688C8.44758 0.929687 7.99986 1.3774 7.99986 1.92969L7.99986 7.99986H1.92969C1.3774 7.99986 0.929687 8.44758 0.929688 8.99986C0.929687 9.55215 1.3774 9.99986 1.92969 9.99986H7.99986V16.07C7.99986 16.6223 8.44758 17.07 8.99986 17.07C9.55215 17.07 9.99986 16.6223 9.99986 16.07V9.99986H16.07C16.6223 9.99986 17.07 9.55215 17.07 8.99986C17.07 8.44758 16.6223 7.99986 16.07 7.99986H9.99986V1.92969Z" fill="white"/>
                    </svg></div>
                <div className={styles.vote_plus}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path d="M9.99986 1.92969C9.99986 1.3774 9.55215 0.929687 8.99986 0.929688C8.44758 0.929687 7.99986 1.3774 7.99986 1.92969L7.99986 7.99986H1.92969C1.3774 7.99986 0.929687 8.44758 0.929688 8.99986C0.929687 9.55215 1.3774 9.99986 1.92969 9.99986H7.99986V16.07C7.99986 16.6223 8.44758 17.07 8.99986 17.07C9.55215 17.07 9.99986 16.6223 9.99986 16.07V9.99986H16.07C16.6223 9.99986 17.07 9.55215 17.07 8.99986C17.07 8.44758 16.6223 7.99986 16.07 7.99986H9.99986V1.92969Z" fill="white"/>
                    </svg></div>

                </div>
                <div className={styles.B}>

                <div className={styles.copy}>B</div>
                <div  className={styles.vote_plus}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path d="M9.99986 1.92969C9.99986 1.3774 9.55215 0.929687 8.99986 0.929688C8.44758 0.929687 7.99986 1.3774 7.99986 1.92969L7.99986 7.99986H1.92969C1.3774 7.99986 0.929687 8.44758 0.929688 8.99986C0.929687 9.55215 1.3774 9.99986 1.92969 9.99986H7.99986V16.07C7.99986 16.6223 8.44758 17.07 8.99986 17.07C9.55215 17.07 9.99986 16.6223 9.99986 16.07V9.99986H16.07C16.6223 9.99986 17.07 9.55215 17.07 8.99986C17.07 8.44758 16.6223 7.99986 16.07 7.99986H9.99986V1.92969Z" fill="white"/>
                    </svg></div>
                <div  className={styles.vote_plus}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path d="M9.99986 1.92969C9.99986 1.3774 9.55215 0.929687 8.99986 0.929688C8.44758 0.929687 7.99986 1.3774 7.99986 1.92969L7.99986 7.99986H1.92969C1.3774 7.99986 0.929687 8.44758 0.929688 8.99986C0.929687 9.55215 1.3774 9.99986 1.92969 9.99986H7.99986V16.07C7.99986 16.6223 8.44758 17.07 8.99986 17.07C9.55215 17.07 9.99986 16.6223 9.99986 16.07V9.99986H16.07C16.6223 9.99986 17.07 9.55215 17.07 8.99986C17.07 8.44758 16.6223 7.99986 16.07 7.99986H9.99986V1.92969Z" fill="white"/>
                    </svg></div>
                </div>
            </div>
            

        </div>
    
    );
    
}

export default Vote;