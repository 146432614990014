import React from "react";
import styles from "../../css/left_side.module.css"
import { useRef } from "react";

function Video(){
    const imageInput = useRef();

    const onCickImageUpload = () => {
        imageInput.current.click();
    };

    return(
    
        <div className={styles.button_container} >
            <button className={styles.video_plus} onClick={onCickImageUpload}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path d="M9.99986 1.9292C9.99986 1.37691 9.55215 0.929199 8.99986 0.929199C8.44758 0.929199 7.99986 1.37691 7.99986 1.9292L7.99986 7.99937H1.92969C1.3774 7.99937 0.929687 8.44709 0.929688 8.99937C0.929687 9.55166 1.3774 9.99937 1.92969 9.99937H7.99986V16.0695C7.99986 16.6218 8.44758 17.0695 8.99986 17.0695C9.55215 17.0695 9.99986 16.6218 9.99986 16.0695V9.99937H16.07C16.6223 9.99937 17.07 9.55166 17.07 8.99937C17.07 8.44709 16.6223 7.99937 16.07 7.99937H9.99986V1.9292Z" fill="white"/>
                </svg>
            </button>
            <input type="file" multiple style={{display: "none"}} ref={imageInput} />

        </div>
    
    );
    
}

export default Video;