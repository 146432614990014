import React from "react";
import styles from "../../css/left_side.module.css"

function Profile(){
    return(
        <>

        <div className={styles.profile}>프로필</div>

        </>
    );
}

export default Profile;