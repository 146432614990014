import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navigation from './navigation/Navigation';
import Team from './Team/Team';
import Chat from './chat/Chat';
import Post from './post/Post';
import Profile from './profile/Profile';
import Login from './login/Login';
import snacksPageStyles from "../css/component-css/snacksPage.module.css";


function Snacks(){

    return(
        <div className={snacksPageStyles.grid}>
            <Navigation />
            <div className={snacksPageStyles.center}>
            <Routes>
                <Route exact path="/" element={<Team />} />
                <Route path="/team" element={<Team />} />
                <Route path="/chat/*" element={<Chat />} />
                <Route path="/post" element={<Post />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/login" element={<Login />} />
            </Routes>
            </div>
            <div className={snacksPageStyles.right}></div>
        </div>
    );
}

export default Snacks;