import "../../css/login-css/Login.css";
import { useState } from 'react';
import { Link } from "react-router-dom";

function Login() {
  const [inputId, setInputId] = useState('');
  const [inputPassword, setInputPassword] = useState('');
  const [isJoinMSHovered, setIsJoinMSHovered] = useState(false);
  const [isFindAcHovered, setIsFindAcHovered] = useState(false);

  const idChange = (e) => {
    setInputId(e.target.value);
  };

  const pwChange = (e) => {
    setInputPassword(e.target.value);
  };

  const handleLogin = () => {
    if (inputId && inputPassword) {
      console.log('로그인 성공!');
    } else {
      console.log('아이디와 비밀번호를 입력하세요.');
    }
  };

  const handleJoinMSMouseEnter = () => {
    setIsJoinMSHovered(true);
  };

  const handleJoinMSMouseLeave = () => {
    setIsJoinMSHovered(false);
  };

  const handleFindAcMouseEnter = () => {
    setIsFindAcHovered(true);
  };

  const handleFindAcMouseLeave = () => {
    setIsFindAcHovered(false);
  };

  return (
    <div className="App">
      <div id="loginBox">
        <div>
        <h2 id="login-snacks">SNACKS</h2>
        <div>
          <input id="id-input"
          className="login-input"
            type="text"
            value={inputId}
            onChange={idChange}
            placeholder="ID"
          />
        </div>
        <div>
          <input id="password-input"
          className="login-input"
            type="password"
            value={inputPassword}
            onChange={pwChange}
            placeholder="Password"
          />
        </div>
        <button id="login-button"
          onClick={handleLogin}
          disabled={!inputId || !inputPassword}
          style={{
            color: 'white',
            backgroundColor: inputId && inputPassword ? '#FA5B5B' : 'black'
          }}
        >
          로그인
        </button>
      <div id="under-login-button">
        <div id="join-membership">
          <Link to='/signup/agree'>회원가입</Link>
        </div>
        <div id="find-an-account">
          계정찾기
        </div>
      </div>
      </div>
      </div>
    </div>
  );
}

export default Login;