import "../../css/signup-css/app.css";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function Header() {
  return <div className="header">SNACKS</div>;
}

function UnderBar() {
  return (
    <div className="main_set__under_bar">
      <div className="main_set__under_bar_color"></div>
    </div>
  );
}

const Modal = ({ closeModal }) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-content-center">
          <div className="modal-content-center-top">
            <div className="modal-content-center-top-title">이용약관</div>
            <button className="close-button" onClick={closeModal}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </button>
          </div>
          <div className="modal-content-center-text">
          1. 이 약관은 회원과 회사 사이에 계약 관계를 설정하며, 회원은 이 약관의 내용에 동의함으로써 회사의 서비스를 이용할 수 있습니다.
          </div>
        </div>
      </div>
    </div>
  );
};

function VectorIcon() {
  return (
    <svg
      width="9"
      height="13"
      viewBox="0 0 9 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.677246 11.1273L5.24254 6.65405L0.677246 2.18078L2.08272 0.806641L8.06345 6.65405L2.08272 12.5015L0.677246 11.1273Z"
        fill="#D9D9D9"
      />
    </svg>
  );
}

function Agree() {
  const [allCheck, setAllCheck] = useState(false);
  const [ageCheck, setAgeCheck] = useState(false);
  const [useCheck, setUseCheck] = useState(false);
  const [marketingCheck, setMarketingCheck] = useState(false);

  const allBtnEvent = () => {
    if (allCheck === false) {
      setAllCheck(true);
      setAgeCheck(true);
      setUseCheck(true);
      setMarketingCheck(true);
    } else {
      setAllCheck(false);
      setAgeCheck(false);
      setUseCheck(false);
      setMarketingCheck(false);
    }
  };

  const ageBtnEvent = () => {
    if (ageCheck === false) {
      setAgeCheck(true);
    } else {
      setAgeCheck(false);
    }
  };

  const useBtnEvent = () => {
    if (useCheck === false) {
      setUseCheck(true);
    } else {
      setUseCheck(false);
    }
  };

  const marketingBtnEvent = () => {
    if (marketingCheck === false) {
      setMarketingCheck(true);
    } else {
      setMarketingCheck(false);
    }
  };

  useEffect(() => {
    if (ageCheck === true && useCheck === true && marketingCheck === true) {
      setAllCheck(true);
    } else {
      setAllCheck(false);
    }
  }, [ageCheck, useCheck, marketingCheck]);

  const [modalOpen, setModalOpen] = useState(false);

  const handleClick = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <div className="app_set">
      <div className="app_set_center">
        <Header />
        <div className="main_set">
          <div className="main_set__title">SNACKS 약관동의</div>
          <UnderBar />
          <div className="main_set__all_agree_box">
            <div className="main_set__all_agree_box_left">
              <input
                className="checkbox_"
                type="checkbox"
                id="all-check"
                checked={allCheck}
                onChange={allBtnEvent}
                style={{ backgroundColor: allCheck ? "#FA5B5B" : "initial" }}
              />
            </div>
            <div className="main_set__all_agree_box_right">
              <div className="main_set__all_agree_box_right__title">
                전체 동의 하기
              </div>
              <div className="main_set__all_agree_box_right__sudtitle">
                전체 동의는 선택 목적에 대한 동의를 포함하고 있으며, 선택 목적에
                대한 동의를 거부해도 서비스 이용이 가능합니다.
              </div>
            </div>
          </div>
          <UnderBar />
          <div className="main_set__agree_box">
            <div className="main_set__agree_box_checkbox">
              <input
                className="checkbox_"
                type="checkbox"
                id="check1"
                checked={ageCheck}
                onChange={ageBtnEvent}
                style={{ backgroundColor: ageCheck ? "#FA5B5B" : "initial" }}
              />
            </div>
            <div className="main_set__agree_box_title">
              (필수) 이용약관
            </div>
            <div className="main_set__agree_box_icon">
              <button
                className="main_set__agree_box_icon_v"
                onClick={handleClick}
              >
                <VectorIcon />
              </button>
              {modalOpen && <Modal closeModal={handleClose} />}
            </div>
          </div>
          <div className="main_set__agree_box">
            <div className="main_set__agree_box_checkbox">
              <input
                className="checkbox_"
                type="checkbox"
                id="check2"
                checked={useCheck}
                onChange={useBtnEvent}
                style={{ backgroundColor: useCheck ? "#FA5B5B" : "initial" }}
              />
            </div>
            <div className="main_set__agree_box_title">
              (필수) 개인정보 처리 방침
            </div>
            <div className="main_set__agree_box_icon">
              <button
                className="main_set__agree_box_icon_v"
                onClick={handleClick}
              >
                <VectorIcon />
              </button>
            </div>
          </div>
          <UnderBar />
          <div className="main_set__agree_box">
            <div className="main_set__agree_box_checkbox">
              <input
                className="checkbox_"
                type="checkbox"
                id="check3"
                checked={marketingCheck}
                onChange={marketingBtnEvent}
                style={{
                  backgroundColor: marketingCheck ? "#FA5B5B" : "initial",
                }}
              />
            </div>
            <div className="main_set__agree_box_title">
              (선택) 마케팅 정보 수집 및 수신동의
            </div>
            <div className="main_set__agree_box_icon">
              <button
                className="main_set__agree_box_icon_v"
                onClick={handleClick}
              >
                <VectorIcon />
              </button>
            </div>
          </div>
          <Link to='/signup/user'><div className="main_set__underbtn">
            <div
              style={{
                backgroundColor:
                  ageCheck && useCheck
                    ? "#FA5B5B"
                    : allCheck
                    ? "#FA5B5B"
                    : "#d9d9d9",
                transition: "background 0.5s",
                color:
                  ageCheck && useCheck
                    ? "#FFFFFF"
                    : allCheck
                    ? "#FFFFFF"
                    : "#000000",
              }}
              className="main_set__underbtn_color"
            >
              다음
            </div>
          
          </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Agree;
