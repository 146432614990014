import TeamInfoBoxStyles from '../../css/team-css/TeamInfoBox.module.css';

function TeamInfoBox({teamInfo, onClick}) {
    console.log(teamInfo);
    return (
        <div className={TeamInfoBoxStyles.team_modal_back}>
            <div className={TeamInfoBoxStyles.team_modal}>
                <div className={TeamInfoBoxStyles.team_modal_up_box}>
                    <div className={TeamInfoBoxStyles.team_modal_setting}></div>
                    <div className={TeamInfoBoxStyles.team_modal_team_title}></div>
                    <div className={TeamInfoBoxStyles.team_modal_close} onClick={onClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L4.58515 5.99937L0.292893 10.2916C-0.0976311 10.6822 -0.0976311 11.3153 0.292893 11.7058C0.683417 12.0964 1.31658 12.0964 1.70711 11.7058L5.99937 7.41358L10.2916 11.7058C10.6821 12.0963 11.3153 12.0963 11.7058 11.7058C12.0963 11.3153 12.0963 10.6821 11.7058 10.2916L7.41358 5.99937L11.7058 1.70713C12.0963 1.3166 12.0963 0.683438 11.7058 0.292914C11.3153 -0.0976105 10.6821 -0.0976105 10.2916 0.292914L5.99937 4.58515L1.70711 0.292893Z" fill="white"/>
                        </svg>
                    </div>
                </div>
                <div className={TeamInfoBoxStyles.team_modal_down_box}>
                    <div className={TeamInfoBoxStyles.team_modal_down_box_team_info}>
                        <div className={TeamInfoBoxStyles.team_modal_down_box_team_info_important}>
                            <div className={TeamInfoBoxStyles.team_modal_game_image_box}><img  className={TeamInfoBoxStyles.team_modal_game_image_box_img} src='https://play-lh.googleusercontent.com/VSwHQjcAttxsLE47RuS4PqpC4LT7lCoSjE7Hx5AW_yCxtDvcnsHHvm5CTuL5BPN-uRTP=s96-rw' alt='team-game-img' /></div>
                            <div className={TeamInfoBoxStyles.team_modal_down_box_team_info_very_important}>
                                <div className={TeamInfoBoxStyles.team_modal_down_box_team_info_very_important_title}>{teamInfo.teamBoxTitle}</div>
                                <div className={TeamInfoBoxStyles.team_modal_down_box_team_info_very_important_datetime_box}>
                                    <div className={TeamInfoBoxStyles.team_modal_down_box_team_info_very_important_date}>{teamInfo.teamDate}</div>
                                    <div className={TeamInfoBoxStyles.team_modal_down_box_team_info_very_important_time}>{teamInfo.teamTime}</div>
                                </div>
                                <div className={TeamInfoBoxStyles.team_modal_down_box_team_info_very_important_game_title_member_num_box}>
                                    <div className={TeamInfoBoxStyles.team_modal_down_box_team_info_very_important_game_title}>{teamInfo.gameTitle}</div>
                                    <div className={TeamInfoBoxStyles.team_modal_down_box_team_info_very_important_slash}>|</div>
                                    <div className={TeamInfoBoxStyles.team_modal_down_box_team_info_very_important_member_num}>최대인원 {teamInfo.teamMemberNum}</div>
                                </div>
                            </div>
                        </div>
                        <div className={TeamInfoBoxStyles.team_modal_down_box_team_info_notice}>
                        
🎮 마인크래프트 게임을 같이 즐길 동료를 모집합니다! 함께 모험하며 새로운 친구들과 즐거운 시간 보내고 싶어요. 어린이든 어른이든 누구나 환영해요. 서로 도와주고 배려하는 따뜻한 분위기에서 자유롭게 즐겨봐요! 새로운 아이디어를 제시하고 창의력을 발휘하여 멋진 세계를 만들어봅시다. 즐겁고 편안한 분위기에서 게임을 즐길 준비가 되셨다면 지금 바로 합류하세요! 우리 팀에서 함께 마인크래프트의 재미를 만끽합시다! 🏰
                        </div>
                    </div>
                    <div className={TeamInfoBoxStyles.team_modal_down_box_team_member_info}>
                        <div className={TeamInfoBoxStyles.team_modal_down_box_team_member_info_title}>참가인원</div>
                        <div className={TeamInfoBoxStyles.team_modal_down_box_team_member_info_list}></div>
                    </div>
                    <div className={TeamInfoBoxStyles.team_modal_team_info_button_container}>
                        <button className={TeamInfoBoxStyles.team_modal_submit}>참가신청</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TeamInfoBox;