import LeftTeamChatListStyles from '../../css/chat-css/LeftTeamChatList.module.css';


function LeftTeamChatList({chatList, onClick}){
    return (
        <div className= {LeftTeamChatListStyles.left_team_chat_list}>
            {
            chatList.map((chatRooom)=>{
                return <LeftTeamChat chatRoom = {chatRooom} onClick = {onClick}/>
            })}
            

        </div>
    );
}

function LeftTeamChat({chatRoom, onClick}){
    return (
        <div className={LeftTeamChatListStyles.left_team_chat_box} onClick = {onClick}>
            <div className={LeftTeamChatListStyles.left_team_chat_box_img_box}>
                <img src={chatRoom.imageUri} alt="team-img" className={LeftTeamChatListStyles.left_team_chat_box_img} />
            </div>
            <div className={LeftTeamChatListStyles.left_team_chat_box_reading_num}>{chatRoom.numberOfUnreadMessage}</div>
        </div>
    );
}


export default LeftTeamChatList;