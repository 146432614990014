import '../../css/team-css/TeamPage.css';
import TeamBoxList from './TeamBox';
import {useState} from 'react';

function TeamPage({items}) {
    const [order, setOrder] = useState('createdAt');
    const handleSoonClick = () => setOrder('teamDate');
    const handleCreatedAtClick = () => setOrder('createdAt');
    const sortedItems = items.sort((a, b)=>a[order]-b[order]);
    return (
        <div className="team-page">
            <div className="team-page-header">
                <h2 className="team-page-title">SNACKS</h2>
                <div className="team-search-box">
                    <input className="team-search-input" type="text"></input>
                    <button className="search-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M11.4351 10.0629H10.7124L10.4563 9.8159C11.3528 8.77301 11.8925 7.4191 11.8925 5.94626C11.8925 2.66209 9.23042 0 5.94626 0C2.66209 0 0 2.66209 0 5.94626C0 9.23042 2.66209 11.8925 5.94626 11.8925C7.4191 11.8925 8.77301 11.3528 9.8159 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94626 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94626C1.82962 3.66838 3.66838 1.82962 5.94626 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94626C10.0629 8.22413 8.22413 10.0629 5.94626 10.0629Z" fill="black"/>
                        </svg>
                    </button>
                    <button className="reload-btn"></button>
                </div>
                <div className='team-search-filter'>
                    필터
                    <button onClick={handleSoonClick}>소멸순정렬</button>
                    <button onClick={handleCreatedAtClick}>생성순정렬</button>
                </div>
            </div>
            <div className="team-page-main">
                <TeamBoxList items={sortedItems}/>
                <div className='team-page-blank'></div>
            </div>
        </div>
    );
}

export default TeamPage;